<template>
  <h1 @click="logging">My Data</h1>
</template>

<script>
import { handleAuth } from "@/mixins/handleAuth";
export default {
  mixins: [handleAuth],
  methods: {
    async logging() {
      const rawResponse = await fetch(
        process.env.VUE_APP_API_HOST + "/users/myself",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await this.getAccessToken()),
          },
        }
      );
      const content = await rawResponse.json();
      console.log(content);
    },
  },
};
</script>
